import React from "react";
import camera from "../../../../assets/img/camera.png";
import frank from "../../../../assets/img/frank.png";
import Esso from "../../../../assets/img/Esso.png";
import gallery from "../../../../assets/img/gallery.png";
import homedepot from "../../../../assets/img/homedepot.png";
import VisitorJourneyBackground from "../../../../assets/img/visitor-Journey-Background.svg";
import { useMediaQuery } from "react-responsive";

const VisitorJourney = () => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isDesktop = useMediaQuery({ minWidth: 1441 });
  const journeyData = [
    {
      name: "Granville Island",
      image: camera,
      location: "Richmond, BC",
    },
    {
      name: "Esso",
      image: Esso,
      location: "Vancouver, BC",
    },
    {
      name: "Frank",
      image: frank,
      location: "",
    },
    {
      name: "Granville Island",
      image: gallery,
      location: "Vancouver, BC",
    },
    {
      name: "Home Depot",
      image: homedepot,
      location: "Burnaby, BC",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        // padding: "1.5rem",
        borderRadius: "0.5rem",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          background: `url(${VisitorJourneyBackground}) no-repeat center center`,
          backgroundColor: "rgba(192, 235, 251, 0.5)",
          backgroundSize: "cover",
          paddingBottom: isDesktop ? "5%" : "10%",
          height: "100%",
          width: "100%",
        }}
      >
        <h2
          style={{
            fontSize: "16px",
            paddingTop: "18px",
            paddingLeft: "18px",
            fontFamily: "Gotham",
            fontWeight: "500",
            color: "#002857",
            marginBottom: "3.5rem",
          }}
        >
          Visitor Journey
        </h2>

        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: isMobile ? "11%" : isDesktop ? "17%" : "14%",
            marginLeft: isMobile ? "2%" : isDesktop ? "4%" : "6%",
          }}
        >
          {journeyData.map((stop, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 10,
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#002857",
                  fontFamily: "Gotham",
                  marginBottom: "7px",
                  display: stop.name === "Frank" ? "none" : "block",
                }}
              >
                {stop.name}
              </div>
              <div
                style={{
                  width: "4rem",
                  height: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: stop.name === "Granville Island" ? "#D9D9D9" : "",
                  border:
                    stop.name === "Granville Island" ? "1px solid #002857" : "",
                  marginBottom: "0.75rem",
                  marginTop: "17px",
                }}
              >
                <img
                  src={stop.image || "/placeholder.svg"}
                  alt={stop.name || "Journey stop"}
                  style={{
                    maxWidth:
                      stop.name === "Frank"
                        ? isMobile
                          ? "160%"
                          : "190%"
                        : stop.name === "Granville Island"
                        ? "60%"
                        : "80%",
                    maxHeight:
                      stop.name === "Frank"
                        ? isMobile
                          ? "110%"
                          : "160%"
                        : stop.name === "Granville Island"
                        ? "60%"
                        : "80%",
                    marginTop:
                      stop.name === "Frank"
                        ? isMobile
                          ? "10px"
                          : "32px"
                        : stop.name === "Granville Island"
                        ? ""
                        : "",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: "10px",
                  fontFamily: "Gotham",
                  color: "#002857",
                  fontWeight: "500",
                }}
              >
                {stop.location}
              </div>
            </div>
          ))}

          {/* Connecting lines */}
          <div
            style={{
              position: "absolute",
              top: "67px",
              left: "3%",
              right: "3%",
              height: "1px",
              background: "#002857",
              zIndex: 0,
              width: "89%",
            }}
          >
            {journeyData.map(
              (_, index) =>
                index < journeyData.length - 1 && (
                  <div
                    key={index}
                    style={{
                      position: "absolute",
                      top: 0,
                      height: "100%",
                      background: "#3182ce",
                      left: `${
                        (index + 1) * (100 / (journeyData.length - 1))
                      }%`,
                      width: "2px",
                    }}
                  />
                )
            )}
          </div>
        </div>
      </div>

      <div
      //  style={{ marginTop: "3rem" }}
      >
        <h3
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#002857",
            marginBottom: "1.5rem",
            paddingTop: "18px",
            paddingLeft: "18px",
            fontFamily: "Gotham",
          }}
        >
          Details
        </h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: isMobile ? "6rem" : "10rem",
            marginLeft: "7%",
            paddingBottom: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "9px",
            }}
          >
            <div
              style={{
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                //  border: "1px solid #e2e8f0",
                borderRadius: "0.5rem",
              }}
            >
              <img
                src={Esso || "/placeholder.svg"}
                alt="Esso"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#002857",
                  fontFamily: "Gotham",
                  fontWeight: "500",
                }}
              >
                Name
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div
                style={{
                  fontSize: "1.875rem",
                  fontFamily: "Gotham",
                  fontWeight: "500",
                  color: "#002857",
                }}
              >
                15
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#002857",
                  fontWeight: "500",
                  marginLeft: "4px",
                  fontFamily: "Gotham",
                }}
              >
                mins
              </div>
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "#002857",
                marginTop: "15px",
                fontFamily: "Gotham",
                fontWeight: "500",
              }}
            >
              Dwell Time
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Gotham",
            }}
          >
            <div
              style={{
                fontSize: "1.875rem",
                fontWeight: "500",
                color: "#002857",
              }}
            >
              100
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "#002857",
                fontWeight: "500",
                fontFamily: "Gotham",
                marginTop: "15px",
              }}
            >
              Affinity Score
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorJourney;

import axios from "axios";
import {
  GET_INDIVIDUALS_TABLE_DATA,
  GET_INDIVIDUALS_TABLE_DATA_FAIL,
  GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
  GET_INDIVIDUALS_TAB_DATA,
  GET_INDIVIDUALS_TAB_DATA_FAIL,
  GET_INDIVIDUALS_TAB_DATA_SUCCESS,
  GET_SEGMENT_TAB_DATA,
  GET_SEGMENT_TAB_DATA_FAIL,
  GET_SEGMENT_TAB_DATA_SUCCESS,
  FETCH_DOWNLOAD_REQUEST,
  FETCH_DOWNLOAD_LINK_SUCCESS,
    // FETCH_AUDIENCE_REQUEST, FETCH_AUDIENCE_SUCCESS, FETCH_AUDIENCE_FAILURE,
    ADD_AUDIENCE_SUCCESS,
    //  UPDATE_AUDIENCE_SUCCESS, DELETE_AUDIENCE_SUCCESS,
  FETCH_DOWNLOAD_LINK_FAILURE,
} from "../constant/insights";
import { notification } from 'antd';
import {
  individualsListQuery,
  individualsQuery,
  segmentQuery,
} from "../constant/insights-queries";

export const getSegmentTabData = ({ startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_SEGMENT_TAB_DATA,
    });

    // const databillityStr = localStorage.getItem("databillity");
    // const databillity = databillityStr ? JSON.parse(databillityStr) : null;
    // let databillityId;
    // if (databillityStr) {
    //   databillityId = databillity.databillity_id;
    // }

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: segmentQuery,
        variables: {
          startDate: startDate,
          endDate: endDate,
          databillityId: process.env.REACT_APP_DATABILLITY_ID,
        },
      },
      // {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Credentials': 'true',
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Methods': '*',
      //     'Access-Control-Allow-Headers': '*',
      //   },
      // }
    );

      dispatch({
        type: GET_SEGMENT_TAB_DATA_SUCCESS,
        payload: response.data.data.segmentTabQuery,
      });
    } catch (error) {
 
      dispatch({
        type: GET_SEGMENT_TAB_DATA_FAIL,
        payload: error.message,
      });
    }
  };

export const getIndividualsTabData = ({ customerId, startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_INDIVIDUALS_TAB_DATA,
    });

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: individualsQuery,
        variables: {
          customerId: customerId,
          startDate: startDate,
          endDate: endDate,
        },
      });

      dispatch({
        type: GET_INDIVIDUALS_TAB_DATA_SUCCESS,
        payload: response.data.data.individualsTabQuery,
      });
    } catch (error) {
  
      dispatch({
        type: GET_INDIVIDUALS_TAB_DATA_FAIL,
        payload: error.message,
      });
    }
  };

export const getIndividualsTableData = ({ startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_INDIVIDUALS_TABLE_DATA,
    });

    // const databillityStr = localStorage.getItem("databillity");
    // const databillity = databillityStr ? JSON.parse(databillityStr) : null;
    // let databillityId;
    // if (databillityStr) {
    //   databillityId = databillity.databillity_id;
    // }

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: individualsListQuery,
        variables: {
          databillityId: process.env.REACT_APP_DATABILLITY_ID,
          startDate: startDate,
          endDate: endDate,
        },
      });

      dispatch({
        type: GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
        payload:
          response.data.data.individualsTableListQuery.individualsCustomerList,
      });
    } catch (error) {

      dispatch({
        type: GET_INDIVIDUALS_TABLE_DATA_FAIL,
        payload: error.message,
      });
    }
  };
  export const fetchDownloadLink = (fileName) => async (dispatch) => {
    dispatch({ type: FETCH_DOWNLOAD_REQUEST });
  
    try {
      const response = await axios.get(
        ` ${process.env.REACT_APP_LOGIN_API_URL}/api/download-tenant-data?fileName=${fileName}`
      );
  
      // Log the response to see what you're getting
      console.log("Download Response:", response);
  
      // Dispatch success action with the URL
      dispatch({
        type: FETCH_DOWNLOAD_LINK_SUCCESS, // Ensure correct action type
        payload: response.data.url, // Assign URL from response
      });
    } catch (error) {
      // Log the error response from Axios
      console.error("Error fetching download link:", error);
  
      // Dispatch failure action
      dispatch({
        type: FETCH_DOWNLOAD_LINK_FAILURE,
        payload: error.response ? error.response.data.message : error.message,
      });
  
      // Show error notification with a custom message
      const errorMessage = error.response
        ? error.response.data.message || "An error occurred while fetching the download link."
        : "Network error. Please try again later.";
  
      notification.error({
        message: "Download Error",
        description: errorMessage,
        placement: "topRight",
      });
    }
  };
//   export const fetchAudiences = () => async (dispatch) => {
//     dispatch({ type: FETCH_AUDIENCE_REQUEST });
//     try {
//         const response = await axios.get( `${process.env.REACT_APP_LOGIN_API_URL}/api/audience`);
//         dispatch({ type: FETCH_AUDIENCE_SUCCESS, payload: response.data });
//     } catch (error) {
//         dispatch({ type: FETCH_AUDIENCE_FAILURE, payload: error.message });
//     }
// };

// Add Audience

export const addAudience = (audienceData) => async (dispatch) => {
  try {
      const tenantID = localStorage.getItem('tenantID');

      // Ensure persona is a string
      const updatedAudienceData = {
          ...audienceData,
          databillityId: tenantID,
          persona: Array.isArray(audienceData.persona)
              ? audienceData.persona[0] // Extract first element if array
              : audienceData.persona
      };

      console.log("Final data being sent:", updatedAudienceData);

      const response = await axios.post(
          `${process.env.REACT_APP_LOGIN_API_URL}/api/audience`, 
          updatedAudienceData,
          {
              headers: {
                  "Content-Type": "application/json",
              }
          }
      );

      dispatch({ type: ADD_AUDIENCE_SUCCESS, payload: response.data });
  } catch (error) {
      console.error("Error adding audience:", error);
  }
};




// Update Audience
// export const updateAudience = (audienceId, updatedData) => async (dispatch) => {
//     try {
//         const response = await axios.put( `${process.env.REACT_APP_LOGIN_API_URL}/${audienceId}`, updatedData);
//         dispatch({ type: UPDATE_AUDIENCE_SUCCESS, payload: response.data });
//     } catch (error) {
//         console.error(error);
//     }
// };

// Delete Audience
// export const deleteAudience = (audienceId) => async (dispatch) => {
//     try {
//         await axios.delete( `${process.env.REACT_APP_LOGIN_API_URL}/${audienceId}`);
//         dispatch({ type: DELETE_AUDIENCE_SUCCESS, payload: audienceId });
//     } catch (error) {
//         console.error(error);
//     }
// };
  
  

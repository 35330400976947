import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import preferencesIcon from "../../../../assets/img/preferences-icon.svg";
import { Box, Chip, Grid } from "@mui/material";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  updatePersonas,
  getAllRecommendations,
} from "../../../../redux/actions/dashboard";
import "./style.scss";
const InsightsPreferences = () => {
  const [secPersonabgColor, setSecPersonabgColor] = useState();
  const [secPersonalabelColor, setSecPersonalabelColor] = useState();
  const [userData, setUserData] = useState();
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const [selectedValue, setSelectedValue] = React.useState("");
  const [primaryPersonabgColor, setPrimaryPersonabgColor] = useState();
  const [primaryPersona, setPrimaryPersona] = useState();
  const [personaType, setPersonaType] = useState();
  const [primaryPersonalabelColor, setPrimaryPersonalabelColor] = useState();
  const [secondaryPersona, setSecondaryPersona] = useState();
  const dispatch = useDispatch();
  const { feedByID } = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.auth);
  const personaColors = [
    {
      bgColor: "rgba(2, 175, 239, 0.2)",
      labelColor: "#02AFEF",
    },
    {
      bgColor: "rgba(125, 198, 153, 0.2)",
      labelColor: "#7DC699",
    },
    {
      bgColor: "rgba(254, 164, 46, 0.2)",
      labelColor: "#FEA42E",
    },
    {
      bgColor: "rgba(125, 0, 183, 0.2)",
      labelColor: "#7D00B7",
    },
    {
      bgColor: "rgba(188, 62, 199, 0.2)",
      labelColor: "#BC3EC7",
    },
    {
      bgColor: "rgba(233, 105, 105, 0.2)",
      labelColor: "#E96969",
    },
    {
      bgColor: "rgba(227, 0, 183, 0.2)",
      labelColor: "#E300B7",
    },
    {
      bgColor: "rgba(2, 175, 239, 0.2)",
      labelColor: "#02AFEF",
    },
    {
      bgColor: "rgba(125, 198, 153, 0.2)",
      labelColor: "#7DC699",
    },
    {
      bgColor: "rgba(254, 164, 46, 0.2)",
      labelColor: "#FEA42E",
    },
    {
      bgColor: "rgba(125, 0, 183, 0.2)",
      labelColor: "#7D00B7",
    },
    {
      bgColor: "rgba(188, 62, 199, 0.2)",
      labelColor: "#BC3EC7",
    },
    {
      bgColor: "rgba(233, 105, 105, 0.2)",
      labelColor: "#E96969",
    },
    {
      bgColor: "rgba(227, 0, 183, 0.2)",
      labelColor: "#E300B7",
    },
    {
      bgColor: "rgba(2, 175, 239, 0.2)",
      labelColor: "#02AFEF",
    },
    {
      bgColor: "rgba(125, 198, 153, 0.2)",
      labelColor: "#7DC699",
    },
    {
      bgColor: "rgba(254, 164, 46, 0.2)",
      labelColor: "#FEA42E",
    },
    {
      bgColor: "rgba(125, 0, 183, 0.2)",
      labelColor: "#7D00B7",
    },
    {
      bgColor: "rgba(188, 62, 199, 0.2)",
      labelColor: "#BC3EC7",
    },
    {
      bgColor: "rgba(233, 105, 105, 0.2)",
      labelColor: "#E96969",
    },
    {
      bgColor: "rgba(227, 0, 183, 0.2)",
      labelColor: "#E300B7",
    },
    {
      bgColor: "rgba(2, 175, 239, 0.2)",
      labelColor: "#02AFEF",
    },
    {
      bgColor: "rgba(125, 198, 153, 0.2)",
      labelColor: "#7DC699",
    },
    {
      bgColor: "rgba(254, 164, 46, 0.2)",
      labelColor: "#FEA42E",
    },
    {
      bgColor: "rgba(125, 0, 183, 0.2)",
      labelColor: "#7D00B7",
    },
    {
      bgColor: "rgba(188, 62, 199, 0.2)",
      labelColor: "#BC3EC7",
    },
    {
      bgColor: "rgba(233, 105, 105, 0.2)",
      labelColor: "#E96969",
    },
    {
      bgColor: "rgba(227, 0, 183, 0.2)",
      labelColor: "#E300B7",
    },
  ];
  useEffect(() => {
    if (selectedValue) {
      if (personaType === "primary") {
        setPrimaryPersona(selectedValue.personaLabel);
        setPrimaryPersonabgColor(
          personaColors[selectedValue?.personaId ? selectedValue?.personaId : 0]
            .bgColor
        );
        setPrimaryPersonalabelColor(
          personaColors[selectedValue?.personaId ? selectedValue?.personaId : 0]
            .labelColor
        );
      }
      if (personaType === "secondary") {
        setSecondaryPersona(selectedValue.personaLabel);
        setSecPersonabgColor(
          personaColors[selectedValue?.personaId ? selectedValue?.personaId : 0]
            .bgColor
        );
        setSecPersonalabelColor(
          personaColors[selectedValue?.personaId ? selectedValue?.personaId : 0]
            .labelColor
        );
      }
      dispatch(
        updatePersonas(
          token,
          personaType,
          selectedValue?.personaId,
          feedByID?.customerId,
          feedByID?.databillityId
        )
      );
    }
  }, [selectedValue]);
  useEffect(() => {
    if (feedByID) {
      setPrimaryPersona(feedByID?.personaDetails?.PrimaryPersona);
      setSecondaryPersona(feedByID?.personaDetails?.SecondaryPersona);
      if (feedByID?.customerDetails?.email) {
        dispatch(
          getAllRecommendations(token, feedByID?.customerDetails?.email)
        );
      }
      setUserData(feedByID);
    }
  }, [feedByID]);
  const CustomIcon = (props) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ filter: "brightness(1)" }}
        {...props}
      >
        <g clipPath="url(#clip0_104_3585)">
          <path
            d="M10 0C15.5225 0 20 4.4775 20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0ZM10 16.5217C10.8652 16.5355 11.7245 16.377 12.5279 16.0555C13.3313 15.734 14.0626 15.2558 14.6794 14.6488C15.2961 14.0419 15.7859 13.3182 16.1202 12.5201C16.4546 11.722 16.6268 10.8653 16.6268 10C16.6268 9.13468 16.4546 8.27801 16.1202 7.47989C15.7859 6.68176 15.2961 5.95814 14.6794 5.35118C14.0626 4.74422 13.3313 4.26604 12.5279 3.9445C11.7245 3.62297 10.8652 3.4645 10 3.47833C8.2884 3.5057 6.65616 4.20483 5.45544 5.42491C4.25472 6.64498 3.58176 8.28818 3.58176 10C3.58176 11.7118 4.25472 13.355 5.45544 14.5751C6.65616 15.7952 8.2884 16.4943 10 16.5217ZM10 13.2008C9.15131 13.2008 8.33737 12.8637 7.73726 12.2636C7.13714 11.6635 6.8 10.8495 6.8 10.0008C6.8 9.15214 7.13714 8.33821 7.73726 7.73809C8.33737 7.13798 9.15131 6.80083 10 6.80083C10.8487 6.80083 11.6626 7.13798 12.2627 7.73809C12.8629 8.33821 13.2 9.15214 13.2 10.0008C13.2 10.8495 12.8629 11.6635 12.2627 12.2636C11.6626 12.8637 10.8487 13.2008 10 13.2008Z"
            fill="currentColor"
          />
        </g>
      </svg>
    );
  };

  return (
    <Box
      sx={{ flexGrow: 1, pt: 2 }}
      style={
        isMobile
          ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : {}
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="start"
        style={isMobile ? { display: "contents" } : {}}
      >
        <Grid
          item
          xs={11}
          md={6}
          style={{
            paddingBottom: "26px",
            maxWidth: "90%",
            // marginLeft: "10px",
          }}
        >
          <Box
            className="customer-insights sliderBox"
            style={{ marginBottom: "35px", marginLeft: "7%" }}
          >
            <span
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "Gotham",
                color: "#002857",
                fontWeight: "500",
                marginLeft: isMobile ? "6px" : "2px",
              }}
            >
              Preferences
            </span>

            <Carousel
              infinite={true}
              itemsToShow={1}
              slidesToShow={1}
              slidesToScroll={1}
              dots={true}
              arrows={false}
              style={{ fontSize: "12px", marginTop: "10px" }}
            >
              <div>
                <div
                  style={
                    {
                      // marginLeft: isSmallMobile
                      //   ? "0px"
                      //   : isMobile
                      //   ? "16px"
                      //   : "0px",
                    }
                  }
                >
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[0]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      height: "41px",
                      marginBottom: "10px",
                      paddingLeft: "4px",

                      marginLeft: "4px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div
                  style={
                    {
                      // marginLeft: isSmallMobile
                      //   ? "0px"
                      //   : isMobile
                      //   ? "16px"
                      //   : "0px",
                    }
                  }
                >
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[1]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      height: "41px",
                      width: isMobile ? "321px" : "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginBottom: "10px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div
                  style={
                    {
                      // marginLeft: isSmallMobile
                      //   ? "0px"
                      //   : isMobile
                      //   ? "16px"
                      //   : "0px",
                    }
                  }
                >
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[3]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      height: "41px",
                      width: isMobile ? "321px" : "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[4]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",

                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                      height: "41px",
                      marginBottom: "10px",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[5]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",

                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                      height: "41px",
                      marginBottom: "10px",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[6]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                      height: "41px",
                      marginBottom: "10px",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[7]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",

                      borderRadius: "5px",
                      marginTop: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                      height: "41px",
                      marginBottom: "10px",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[8]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[9]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[10]}
                    variant="filled"
                    style={{
                      marginTop: "8px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      height: "41px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[11]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px", // Adjust size as needed
                            height: "17px",
                            marginRight: "10px", // Space between icons
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.primaryPreferences[2]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(2, 175, 239, 0.3)",
                      color: "#02afef",
                      height: "41px",
                    }}
                    icon={
                      <>
                        <img
                          src={preferencesIcon}
                          alt="Preferences Icon"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />{" "}
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",

                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />{" "}
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "4px",
                      borderRadius: "5px",
                      height: "41px",

                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "7px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "7px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "7px",
                      borderRadius: "5px",
                      height: "41px",

                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />{" "}
                </div>
              </div>

              <div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      marginTop: "5px",
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "9px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          /// fontSize: "17px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "9px",
                      borderRadius: "5px",
                      height: "41px",
                      marginBottom: "10px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
                <div>
                  <Chip
                    label={userData?.preferenceDetails?.secondaryPreferences[0]}
                    variant="filled"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "unset",
                      paddingLeft: "4px",
                      marginLeft: "9px",
                      borderRadius: "5px",
                      height: "41px",
                      backgroundColor:
                        secPersonabgColor || "rgba(125, 198, 153, 0.3)",
                      color: secPersonalabelColor || "#7DC699",
                    }}
                    icon={
                      <CustomIcon
                        style={{
                          color: secPersonalabelColor || "#7DC699",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsightsPreferences;

import React from "react";
const AffinityRatio = () => {
  const barChartData = [
    { label: "Services", value: 5.4 },
    { label: "Entertainment", value: 4.2 },
    { label: "Retail", value: 3.8 },
    { label: "Salon/Spa", value: 4.5 },
    { label: "Hotels", value: 4.9 },
    { label: "Restaurants", value: 3.5 },
    { label: "Services", value: 2.7 },
  ];

  return (
    <div>
      <div
        style={{
          flex: 1,
          background: "#fff",
          padding: "1rem",
          borderRadius: "8px",
        }}
      >
        <h3
          style={{
            color: "#002857",
            fontWeight: "500",
            fontFamily: "Gotham",
            fontSize: "16px",
          }}
        >
          Affinity Ratio
        </h3>
        <p
          style={{
            paddingTop: "6px",
            color: "#002857",
            fontSize: "12px",
            paddingBottom: "5%",
          }}
        >
          by industry sector
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            position: "relative",
            paddingBottom: "2rem",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "21%",
              right: "3%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              zIndex: 0,
            }}
          >
            {[
              "0",
              "0.5",
              "1",
              "1.5",
              "2",
              "2.5",
              "3",
              "3.5",
              "4",
              "4.5",
              "5",
              "5.5",
            ].map((_, index) => (
              <div
                key={index}
                style={{
                  height: "85%",
                  borderLeft: "1px solid #ccc",
                }}
              ></div>
            ))}
          </div>

          {barChartData.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  flex: 1,
                  fontSize: "10px",
                  color: "#002857",
                  textAlign: "right",

                  marginRight: "7px",
                }}
              >
                {item.label}
              </span>

              <div
                style={{
                  flex: 4,
                  position: "relative",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    width: `${(item.value / 5.6) * 100}%`,

                    color: "#ffffff",
                    height: "100%",
                    background: "#02AFEF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "10px",
                      fontSize: "14px",

                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >
                    {item.value >= 5.4 && item.value}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: 0,
              left: "21%",
              right: "1%",
              justifyContent: "space-between",
              fontSize: "10px",
              color: "#002857",
              paddingTop: "10px",
              zIndex: 2,
            }}
          >
            {[
              "0",
              "0.5",
              "1",
              "1.5",
              "2",
              "2.5",
              "3",
              "3.5",
              "4",
              "4.5",
              "5",
              "5.5",
            ].map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AffinityRatio;

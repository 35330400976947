import { Divider, Modal } from "antd"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { Form, Input, Row, Col, Button, Select, Checkbox } from "antd"
import { useMediaQuery } from "react-responsive"
import { addAudience } from "../../redux/actions/insights"

const CreateAudience = ({ visible, onCancel }) => {
  const [audienceData, setAudienceData] = useState({
    segmentName: "",
    location: "",
    persona: [],
    age: "",
    gender: "",
    income: "",
    description: "",
  })
  const [selectedPersonas, setSelectedPersonas] = useState([])
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 769 })
  const [form] = Form.useForm()
  const [description, setDescription] = useState("")
  const { Option } = Select

  const options = [
    "Needs based",
    "Seasonal",
    "Impulse-Driven",
    "Habitual",
    "Trendsetter/Innovation",
    "Convenience-Driven",
    "Power Buyer",
    "Limited Buyer",
    "Foodie Buyer",
    "Sports Buyer",
    "Ethical/Sustainable Buyer",
  ]

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setAudienceData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handlePersonaChange = (value) => {
    setSelectedPersonas(value)
    setAudienceData((prevData) => ({
      ...prevData,
      persona: value,
    }))
  }

  const handleSelectChange = (value, fieldName) => {
    setAudienceData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const updatedAudienceData = {
        ...audienceData,
        persona: audienceData.persona.join(", "),
        description,
      }

      dispatch(addAudience(updatedAudienceData))
      form.resetFields()
      setAudienceData({
        segmentName: "",
        location: "",
        persona: [],
        age: "",
        gender: "",
        income: "",
        description: "",
      })
      setDescription("")
      onCancel()
    })
  }

  const customDropdownIcon = (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
        fill="#002857"
      />
    </svg>
  )

  return (
    <Modal
      title={
        <span
          style={{
            color: "#02afef",
            fontSize: "12px",
            fontFamily: "Gotham",
            fontWeight: "500",
          }}
        >
          AUDIENCES
        </span>
      }
      visible={visible}
      onCancel={onCancel}
      width={1000}
      footer={null}
      closeIcon={
        <svg xmlns="http://www.w3.org/2000/svg" fill="#002857" viewBox="0 0 24 24" width="24px" height="24px">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      }
    >
      <div
        style={{
          fontWeight: "500",
          fontSize: "20px",
          color: "#002857",
          fontFamily: "Gotham",
          margin: "0",
        }}
      >
        Create Audience
      </div>
      <Divider
        style={{
          borderColor: "#c8c8ce",
          width: isMobile ? "80vw" : "65vw",
          marginLeft: "-33px",
        }}
      />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={[54, 15]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "7px",
                  }}
                >
                  Audience Name
                </span>
              }
              name="segmentName"
              rules={[{ required: true, message: "Please enter audience name" }]}
            >
              <Input
                placeholder=""
                name="segmentName"
                value={audienceData.segmentName}
                onChange={handleInputChange}
                style={{
                  borderRadius: "10px",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "7px",
                  }}
                >
                  Location
                </span>
              }
              name="location"
            >
              <Input
                placeholder="Zip/Postal Code"
                name="location"
                value={audienceData.location}
                onChange={handleInputChange}
                style={{
                  borderRadius: "10px",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: "7px",
                  }}
                >
                  Select Persona(s)
                </span>
              }
              name="persona"
            >
              <Select
                mode="multiple"
                onChange={handlePersonaChange}
                value={audienceData.persona}
                optionLabelProp="label"
                tagRender={(props) => {
                  const { label, value } = props
                  const isLast = value === selectedPersonas[selectedPersonas.length - 1]
                  return (
                    <span
                      style={{
                        color: "#002857",
                        marginRight: 4,
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "16px",
                      }}
                    >
                      {label}
                      {!isLast && ","}{" "}
                    </span>
                  )
                }}
                suffixIcon={customDropdownIcon}
              >
                {options.map((option) => (
                  <Option key={option} value={option} label={option}>
                    <Checkbox checked={selectedPersonas.includes(option)}>
                      <span style={{ marginLeft: "10px" }}>{option}</span>
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: "7px",
                  }}
                >
                  Age
                </span>
              }
              name="age"
            >
              <Select
                value={audienceData.age}
                onChange={(value) => handleSelectChange(value, "age")}
                suffixIcon={customDropdownIcon}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <Option value="18-24">18-24</Option>
                <Option value="25-34">25-34</Option>
                <Option value="35-44">35-44</Option>
                <Option value="45-54">45-54</Option>
                <Option value="55-64">55-64</Option>
                <Option value="65+">65+</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: "7px",
                  }}
                >
                  Gender
                </span>
              }
              name="gender"
            >
              <Select
                value={audienceData.gender}
                onChange={(value) => handleSelectChange(value, "gender")}
                suffixIcon={customDropdownIcon}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: "7px",
                  }}
                >
                  Income
                </span>
              }
              name="income"
            >
              <Select
                value={audienceData.income}
                onChange={(value) => handleSelectChange(value, "income")}
                suffixIcon={customDropdownIcon}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <Option value="0-$29,000">0-$29,000</Option>
                <Option value="$30,000-$74,000">$30,000-$74,000</Option>
                <Option value="$75,000-$99,000">$75,000-$99,000</Option>
                <Option value="$100,000-$149,000">$100,000-$149,000</Option>
                <Option value="$150,000+">$150,000+</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Col md={24} style={{ marginBottom: "8%" }}>
          <Form.Item
            label={
              <span
                style={{
                  fontWeight: "500",
                  color: "#002857",
                  fontFamily: "Gotham",
                  marginTop: "30px",
                  marginBottom: "7px",
                  fontSize: "15px",
                }}
              >
                Describe Your Audience
              </span>
            }
            name="description"
          >
            <Input.TextArea
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{
                width: "100%",
                borderRadius: "12px",
                padding: "10px",
                fontSize: "16px",
                color: "#002857",
                resize: "none",
              }}
              maxLength={50}
            />
            <div
              style={{
                textAlign: "right",
                color: "#999999",
                fontSize: "10px",
                marginTop: "5px",
                fontWeight: "500",
              }}
            >
              50 word limit
            </div>
          </Form.Item>
        </Col>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "18px",
            marginBottom: "35px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#999999",
              color: "white",
              width: isMobile ? "auto" : "145px",
              height: "39px",
              borderRadius: "22px",
              fontFamily: "Gotham",
              fontWeight: "bold",
              fontSize: isMobile ? "12px" : "16px",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#002857",
              color: "white",
              width: isMobile ? "auto" : "145px",
              height: "39px",
              borderRadius: "22px",
              fontFamily: "Gotham",
              fontWeight: "bold",
              fontSize: isMobile ? "12px" : "16px",
            }}
          >
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAudience


export const GET_SEGMENT_TAB_DATA = 'GET_SEGMENT_TAB_DATA';
export const GET_SEGMENT_TAB_DATA_SUCCESS = 'GET_SEGMENT_TAB_DATA_SUCCESS';
export const GET_SEGMENT_TAB_DATA_FAIL = 'GET_SEGMENT_TAB_DATA_FAIL';

export const GET_INDIVIDUALS_TAB_DATA = 'GET_INDIVIDUALS_TAB_DATA';
export const GET_INDIVIDUALS_TAB_DATA_SUCCESS = 'GET_INDIVIDUALS_TAB_DATA_SUCCESS';
export const GET_INDIVIDUALS_TAB_DATA_FAIL = 'GET_INDIVIDUALS_TAB_DATA_FAIL';

export const GET_INDIVIDUALS_TABLE_DATA = 'GET_INDIVIDUALS_TABLE_DATA';
export const GET_INDIVIDUALS_TABLE_DATA_SUCCESS = 'GET_INDIVIDUALS_TABLE_DATA_SUCCESS';
export const GET_INDIVIDUALS_TABLE_DATA_FAIL = 'GET_INDIVIDUALS_TABLE_DATA_FAIL';

export const FETCH_DOWNLOAD_REQUEST = "FETCH_DOWNLOAD_REQUEST";
export const FETCH_DOWNLOAD_LINK_SUCCESS = "FETCH_DOWNLOAD_LINK_SUCCESS";
export const FETCH_DOWNLOAD_LINK_FAILURE = "FETCH_DOWNLOAD_LINK_FAILURE";

// export const FETCH_AUDIENCE_REQUEST = 'FETCH_AUDIENCE_REQUEST';
// export const FETCH_AUDIENCE_SUCCESS = 'FETCH_AUDIENCE_SUCCESS';
// export const FETCH_AUDIENCE_FAILURE = 'FETCH_AUDIENCE_FAILURE';

export const ADD_AUDIENCE_SUCCESS = 'ADD_AUDIENCE_SUCCESS';
// export const UPDATE_AUDIENCE_SUCCESS = 'UPDATE_AUDIENCE_SUCCESS';
// export const DELETE_AUDIENCE_SUCCESS = 'DELETE_AUDIENCE_SUCCESS';
import React, { useEffect, useState } from "react";
import CustomerInsights from "../CustomerInsights";
import { useDispatch, useSelector } from "react-redux";
import CustomersTable from "../AudienceTab/CustomersTable/index";
import { Box, Grid } from "@mui/material";
// import Banner from "./Banner";
import { useMediaQuery } from "react-responsive";
import { getSegmentTabData } from "../../../redux/actions/insights";
import VisitorJourney from "./VisitorJourney";
import AffinityRatio from "./AffinityRatio/index";
import SubsectorCard from "./Subsector";
import BioCard from "../IndividualsTab/BioCard";
import InsightsPreferences from "./Preferences";
import AffinityCategoryName from "./AffinityCategoryName";

const IndividualsTab = (props) => {
  const { startDate, endDate } = props;
  const { insights, isLoading } = useSelector((state) => state.insights);
  const [segmentsData, setSegmentsData] = useState();
  const [customerId, setCustomerId] = useState();
  const [individualsInsights, setIndividualsInsights] = useState();
  const [individualsListing, setIndividualsListing] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(getSegmentTabData({ startDate: startDate, endDate: endDate }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isLoading) {
      setSegmentsData(insights.segment);
    }
  }, [insights, isLoading]);

  return (
    <>
      <Grid
        container
        spacing={{ xs: 5, sm: 8, md: 8 }}
        sx={{ marginBottom: "0px" }}
      >
        <Grid item xl={5.5} lg={5.5} md={12} xs={12}>
          <BioCard />
        </Grid>
        <Grid item xl={6.5} lg={6.5} md={12} xs={12}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: "255px",
            }}
          >
            {" "}
            <InsightsPreferences />
          </div>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} xs={12} sm={12} mt={4} mb={4}>
        <VisitorJourney />
      </Grid>

      <Grid container spacing={{ xs: 3, sm: 6.5, md: 6.5 }}>
        <Grid item xl={6} lg={6} md={12} xs={12} mb={1}>
          <AffinityRatio />
        </Grid>
        <Grid item xl={6} lg={6} md={12} xs={12} mb={1}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: isMobile ? "70vw" : "100%",
            }}
          >
            {" "}
            <AffinityCategoryName />
          </div>
        </Grid>
      </Grid>

      <Grid sm={12} mt={4} mb={2}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            height: "33vw",
          }}
        >
          {" "}
          <SubsectorCard />
        </div>
      </Grid>

      {/* <Grid item xl={12} lg={12} xs={12} sm={12}>
        <CustomersTable
          startDate={startDate}
          endDate={endDate}
          setCustomerId={setCustomerId}
          individualsListing={individualsListing}
          isLoading={isLoading}
        />
      </Grid> */}
    </>
  );
};

export default IndividualsTab;

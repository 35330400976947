import React, { useEffect, useState } from "react";
import subsector from "../../../../assets/img/subsector.png";
const SubsectorCard = () => {
  return (
    <>
      <div
        style={{
          flex: 1,
          background: "#fff",
          padding: "1rem",
          // width: "100%",
          borderRadius: "8px",
          height: "26vw",
        }}
      >
        <h1
          style={{
            color: "#002857",
            fontWeight: "500",
            fontFamily: "Gotham",
            fontSize: "16px",
          }}
        >
          Affinity Ratio by Subsector
        </h1>
        <img src={subsector} style={{ width: "100%", marginTop: "4%" }} />
      </div>
    </>
  );
};
export default SubsectorCard;

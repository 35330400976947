import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  GET_INDIVIDUALS_TABLE_DATA,
  GET_INDIVIDUALS_TABLE_DATA_FAIL,
  GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
  GET_INDIVIDUALS_TAB_DATA,
  GET_INDIVIDUALS_TAB_DATA_FAIL,
  GET_INDIVIDUALS_TAB_DATA_SUCCESS,
  GET_SEGMENT_TAB_DATA,
  GET_SEGMENT_TAB_DATA_FAIL,
  GET_SEGMENT_TAB_DATA_SUCCESS,
  FETCH_DOWNLOAD_LINK_SUCCESS,
    // FETCH_AUDIENCE_REQUEST, FETCH_AUDIENCE_SUCCESS, FETCH_AUDIENCE_FAILURE,
    ADD_AUDIENCE_SUCCESS, 
    // UPDATE_AUDIENCE_SUCCESS, DELETE_AUDIENCE_SUCCESS,
 FETCH_DOWNLOAD_LINK_FAILURE,
} from "../constant/insights";

const initialState = {
  insights: { segment: {}, individual: { table: {}, customer: {} } },
  isLoading: true,
  error: null,
  download: {
    downloadUrl: null,
    error: null,
  },
};

export default function Insights(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;
    case GET_SEGMENT_TAB_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SEGMENT_TAB_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, segment: action.payload },
        isLoading: false,
      };
    case GET_SEGMENT_TAB_DATA_FAIL:
      // toast.error("Failed to fetch Segment Tab Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_INDIVIDUALS_TAB_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INDIVIDUALS_TAB_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, individual: { ...state.insights.individual, customer: action.payload } },
        isLoading: false,
      };
    case GET_INDIVIDUALS_TAB_DATA_FAIL:
      // toast.error("Failed to fetch Individuals Tab Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_INDIVIDUALS_TABLE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INDIVIDUALS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, individual: { ...state.insights.individual, table: action.payload } },
        isLoading: false,
      };
    case GET_INDIVIDUALS_TABLE_DATA_FAIL:
      // toast.error("Failed to fetch Individuals Table Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        insights: { segment: {}, individual: { table: {}, customer: {} } },
        isLoading: true,
        error: null,
      }
      case FETCH_DOWNLOAD_LINK_SUCCESS:
        return { 
          ...state, 
          download: { downloadUrl: action.payload, error: null }
        };
      case FETCH_DOWNLOAD_LINK_FAILURE:
        return { 
          ...state, 
          download: { downloadUrl: null, error: action.payload }
        };
        // case FETCH_AUDIENCE_REQUEST:
        //     return { ...state, loading: true };

        // case FETCH_AUDIENCE_SUCCESS:
        //     return { ...state, loading: false, audiences: action.payload };

        // case FETCH_AUDIENCE_FAILURE:
        //     return { ...state, loading: false, error: action.payload };

        case ADD_AUDIENCE_SUCCESS:
            return { ...state, audiences: [...state.audiences, action.payload] };

        // case UPDATE_AUDIENCE_SUCCESS:
        //     return {
        //         ...state,
        //         audiences: state.audiences.map((audience) =>
        //             audience.audience_id === action.payload.audience_id ? action.payload : audience
        //         ),
        //     };

        // case DELETE_AUDIENCE_SUCCESS:
        //     return {
        //         ...state,
        //         audiences: state.audiences.filter((audience) => audience.audience_id !== action.payload),
        //     };
    default:
      return state;
  }

}

import quickbooks from "../../assets/img/quickbooks.png";
import GitHub from "../../assets/img/Github.png";
import WooCommerce from "../../assets/img/WOO COMMERCE.png";
import HubSpot from "../../assets/img/HubSpot.png";

export const dataConnections = [
  {
    id: 1,
    name: "Quickbooks",
    connected: false,
    logo: quickbooks,
    width: 122,
    height: 31,
  },
  {
    id: 2,
    name: "GitHub",
    connected: false,
    logo: GitHub,
    width: 77,
    height: 20,
  },
  {
    id: 3,
    name: "Google Ads",
    connected: false,
    logo: "https://logowik.com/content/uploads/images/google-ads1838.jpg",
    width: 36,
    height: 36,
  },
  {
    id: 4,
    name: "Google Sheets",
    connected: false,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1200px-Google_Sheets_logo_%282014-2020%29.svg.png",
    width: 27,
    height: 37,
  },
  {
    id: 5,
    name: "HubSpot",
    connected: false,
    logo: HubSpot,
    width: 36,
    height: 38,
  },
  {
    id: 6,
    name: "Salesforce",
    connected: false,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/512px-Salesforce.com_logo.svg.png?20210504050649",
    width: 64,
    height: 45,
  },
  {
    id: 7,
    name: "WooCommerce",
    connected: false,
    logo: WooCommerce,
    width: 137,
    height: 31,
  },

  // {
  //   id: 8,
  //   name: "Google Analytics 4",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/GAnalytics.svg/220px-GAnalytics.svg.png",
  // },
  // {
  //   id: 9,
  //   name: "Google Sheets",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1200px-Google_Sheets_logo_%282014-2020%29.svg.png",
  // },
  // {
  //   id: 10,
  //   name: "Hubspot",
  //   connected: false,
  //   logo: "https://www.svgrepo.com/show/331433/hubspot.svg",
  // },
  // {
  //   id: 11,
  //   name: "Intercom",
  //   connected: false,
  //   logo: "https://static-00.iconduck.com/assets.00/intercom-icon-2048x2048-7bvl7c14.png",
  // },
  // {
  //   id: 12,
  //   name: "LinkedIn Ads",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png",
  // },
  // {
  //   id: 13,
  //   name: "LinkedIn Pages",
  //   connected: false,
  //   logo: "https://icons.veryicon.com/png/o/internet--web/common-social-site-icons-for-the-web/linkedin-90.png",
  // },
  // {
  //   id: 14,
  //   name: "Microsoft Dynamics365",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Dynamics365-color.svg/1200px-Dynamics365-color.svg.png",
  // },
  // {
  //   id: 15,
  //   name: "Nuvei",
  //   connected: false,
  //   logo: "https://logowik.com/content/uploads/images/nuvei1047.logowik.com.webp",
  // },
  // {
  //   id: 16,
  //   name: "PayPal",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/1280px-PayPal.svg.png",
  // },
  // {
  //   id: 17,
  //   name: "Quickbooks",
  //   connected: false,
  //   logo: "https://cdn.worldvectorlogo.com/logos/quickbooks-2.svg",
  // },
  // {
  //   id: 18,
  //   name: "Salesforce",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/512px-Salesforce.com_logo.svg.png?20210504050649",
  // },
  // {
  //   id: 19,
  //   name: "Salesforce Marketing Cloud",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/512px-Salesforce.com_logo.svg.png?20210504050649",
  // },
  // {
  //   id: 20,
  //   name: "SendGrid",
  //   connected: false,
  //   logo: "https://cdn.worldvectorlogo.com/logos/sendgrid-2.svg",
  // },
  // {
  //   id: 21,
  //   name: "ServiceNow",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/ServiceNow_logo.svg/2560px-ServiceNow_logo.svg.png",
  // },
  // {
  //   id: 22,
  //   name: "Smartsheet",
  //   connected: false,
  //   logo: "https://companieslogo.com/img/orig/SMAR-ce38181a.png?t=1652258180",
  // },
  // {
  //   id: 23,
  //   name: "Stripe",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/512px-Stripe_Logo%2C_revised_2016.svg.png",
  // },
  // {
  //   id: 24,
  //   name: "WooCommerce",
  //   connected: false,
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/WooCommerce_logo.svg/2560px-WooCommerce_logo.svg.png",
  // },
  // {
  //   id: 25,
  //   name: "Zendesk",
  //   connected: false,
  //   logo: "https://cdn.worldvectorlogo.com/logos/zendesk-2.svg",
  // },
];

import React from "react";

const AffinityCategoryName = () => {
  const tableData = [
    { sector: "Automotive", subsector: "", categoryName: "", affinity: "" },
    { sector: "Retail", subsector: "", categoryName: "", affinity: "" },
  ];
  return (
    <div>
      <div
        style={{
          flex: 1,
          background: "#fff",
          padding: "1rem",
          borderRadius: "8px",
          //   height: "270px",
        }}
      >
        <h3
          style={{
            fontWeight: "500",
            fontFamily: "Gotham",
            fontSize: "16px",
            color: "#002857",
          }}
        >
          Affinity Ratio
        </h3>
        <p
          style={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "12px",
            color: "#002857",
            marginTop: "6px",
            paddingBottom: "5%",
          }}
        >
          by category name
        </p>
        <table
          style={{
            width: "98%",
            borderCollapse: "collapse",
            marginLeft: "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "0.5rem",
                  textAlign: "left",
                  backgroundColor: "#02AFEF",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              >
                Sector
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "0.5rem",
                  textAlign: "left",
                  backgroundColor: "#02AFEF",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              >
                Subsector
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "0.5rem",
                  textAlign: "left",
                  backgroundColor: "#02AFEF",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              >
                Category Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "0.5rem",
                  textAlign: "left",
                  backgroundColor: "#02AFEF",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              >
                Affinity
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "0.5rem",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "10px",
                    color: "#002857",
                  }}
                >
                  {row.sector}
                </td>
                <td
                  style={{ borderBottom: "1px solid #ddd", padding: "0.5rem" }}
                >
                  {row.subsector}
                </td>
                <td
                  style={{ borderBottom: "1px solid #ddd", padding: "0.5rem" }}
                >
                  {row.categoryName}
                </td>
                <td
                  style={{ borderBottom: "1px solid #ddd", padding: "0.5rem" }}
                >
                  {row.affinity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AffinityCategoryName;
